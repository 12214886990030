.board {
  @apply flex h-full max-h-full w-full flex-1 overflow-x-auto text-gray-700;
}

.react-kanban-board {
  @apply h-full max-h-full min-w-min overflow-y-hidden overflow-x-visible p-0 px-2 pb-4 !important;
}

.react-kanban-board > div {
  @apply h-full max-h-full flex-1 !important;
}

.react-kanban-board > [data-rbd-droppable-id] {
  @apply flex;
}

.react-kanban-column {
  @apply relative m-0 mr-2 flex h-fit max-h-full min-w-[300px] max-w-[300px] flex-col overflow-hidden rounded bg-slate-50 p-2 pr-1 !important;
}

.react-kanban-column > [data-rbd-droppable-id] {
  @apply h-fit max-h-full overflow-y-auto pr-1;
  scrollbar-width: thin;
}

.react-kanban-column > [data-rbd-droppable-id]::-webkit-scrollbar {
  @apply w-2;
}
.react-kanban-column > [data-rbd-droppable-id]::-webkit-scrollbar-track {
  @apply w-2 rounded-full bg-slate-200;
}
.react-kanban-column > [data-rbd-droppable-id]::-webkit-scrollbar-thumb {
  @apply cursor-pointer rounded-full bg-slate-300 transition-colors;
}
.react-kanban-column > [data-rbd-droppable-id]::-webkit-scrollbar-thumb:hover {
  @apply bg-slate-400;
}

[data-rbd-draggable-context-id] > div {
  @apply w-full;
}

.react-kanban-card-skeleton {
  @apply w-full max-w-[284px] !important;
}

.blob1 {
  background: radial-gradient(
      77.73% 77.73% at 98.52% 96.25%,
      #f98bda 0%,
      rgba(128, 76, 239, 0.29) 50.52%,
      rgba(91, 216, 216, 0) 100%
    ),
    radial-gradient(
      141.73% 105.23% at 50% -7.16%,
      #e1f8ff 0%,
      rgba(160, 198, 255, 0) 50.73%,
      rgba(162, 147, 255, 0) 100%
    ),
    radial-gradient(
      112.27% 48.54% at 1.59% 50%,
      rgba(255, 130, 227, 0.5) 0%,
      rgba(255, 123, 234, 0.095) 53.91%,
      rgba(254, 216, 255, 0) 100%
    ),
    linear-gradient(153.07deg, #8177f3 6.37%, rgba(255, 230, 166, 0) 83.82%);
  background-blend-mode: normal, normal, normal, normal, normal, normal;
  filter: blur(0px);
}

.blob2 {
  background: radial-gradient(
      77.95% 77.95% at 74.66% 58.07%,
      rgba(255, 254, 220, 0.8) 0%,
      rgba(255, 255, 255, 0.152) 62.28%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(
      89.67% 70.39% at 93.75% 92.16%,
      #29c2d7 0%,
      rgba(144, 160, 215, 0.49) 52.46%,
      rgba(255, 156, 156, 0) 100%
    ),
    radial-gradient(
      68.86% 68.86% at 94.55% 1.7%,
      #fadc90 0%,
      rgba(250, 220, 144, 0) 100%
    ),
    linear-gradient(
      130.87deg,
      rgba(245, 115, 122, 0.78) 3.47%,
      rgba(245, 115, 122, 0) 77.25%
    );
  background-blend-mode: overlay, normal, normal, normal, normal, normal;
  filter: blur(0px);
}

.blob3 {
  background: radial-gradient(
      62.61% 62.61% at 95.23% 6.02%,
      #160e71 0%,
      rgba(19, 13, 92, 0.26) 54.71%,
      rgba(90, 35, 248, 0) 100%
    ),
    linear-gradient(72.48deg, #ef516d 2.61%, rgba(106, 103, 227, 0) 56.18%),
    radial-gradient(
      45.23% 45.23% at 35.11% -11.02%,
      #7936ae 0%,
      rgba(121, 54, 174, 0) 100%
    ),
    radial-gradient(
      94.51% 124.88% at 94.32% 94.43%,
      rgba(65, 244, 255, 0.78) 0%,
      rgba(131, 218, 255, 0.6552) 32.29%,
      rgba(99, 175, 240, 0.3978) 64.06%,
      rgba(43, 90, 211, 0) 100%
    ),
    linear-gradient(313.04deg, #341d65 0.93%, #604aea 125.68%);
  background-blend-mode: normal, normal, normal, normal, normal, normal;
  filter: blur(0px);
}

.blob4 {
  background: linear-gradient(180deg, #ef516d 0%, rgba(239, 81, 109, 0) 100%),
    radial-gradient(
      94.51% 124.88% at 94.32% 94.43%,
      rgba(65, 244, 255, 0.78) 0%,
      rgba(131, 218, 255, 0.6552) 32.29%,
      rgba(99, 175, 240, 0.3978) 64.06%,
      rgba(43, 90, 211, 0) 100%
    ),
    linear-gradient(313.04deg, #341d65 0.93%, #604aea 125.68%);
  background-blend-mode: normal, normal, normal, normal, normal, normal;
  filter: blur(0px);
}

.blob5 {
  background: linear-gradient(180deg, #341d65 0%, rgba(52, 29, 101, 0) 100%),
    radial-gradient(
      94.51% 124.88% at 94.32% 94.43%,
      rgba(65, 244, 255, 0.78) 0%,
      rgba(128, 217, 255, 0.78) 46.52%,
      rgba(65, 198, 255, 0) 100%
    ),
    linear-gradient(41deg, #fffce6 4.33%, rgba(255, 252, 230, 0) 73.7%);
  background-blend-mode: normal, normal, normal, normal, normal, normal;
  filter: blur(0px);
}

.blob6 {
  background: radial-gradient(
      73.4% 100.98% at 95.54% 95.05%,
      rgba(255, 156, 65, 0.78) 0%,
      rgba(255, 168, 88, 0) 100%
    ),
    linear-gradient(197.37deg, #fff0a4 -0.38%, rgba(255, 240, 164, 0) 101.89%),
    radial-gradient(
      58.85% 73.83% at 9.82% 3.13%,
      rgba(49, 132, 255, 0.8) 0%,
      rgba(56, 75, 247, 0.208) 52.16%,
      rgba(62, 246, 246, 0) 100%
    ),
    linear-gradient(
      117.39deg,
      #3e9ef6 3.91%,
      rgba(238, 43, 183, 0.33) 37.48%,
      rgba(246, 62, 106, 0) 73.98%
    ),
    linear-gradient(306.53deg, #ffaadd 19.83%, rgba(255, 170, 221, 0) 97.33%);
  background-blend-mode: normal, normal, normal, normal, normal, normal;
  filter: blur(0px);
}

.blob7 {
  background: linear-gradient(
      197.37deg,
      #7450db -0.38%,
      rgba(138, 234, 240, 0) 101.89%
    ),
    linear-gradient(
      115.93deg,
      #3e88f6 4.86%,
      rgba(62, 180, 246, 0.33) 38.05%,
      rgba(62, 235, 246, 0) 74.14%
    ),
    radial-gradient(
      56.47% 76.87% at 6.92% 7.55%,
      rgba(62, 136, 246, 0.7) 0%,
      rgba(62, 158, 246, 0.182) 52.16%,
      rgba(62, 246, 246, 0) 100%
    ),
    linear-gradient(306.53deg, #2ee4e3 19.83%, rgba(46, 228, 227, 0) 97.33%);
  background-blend-mode: normal, normal, normal, normal, normal, normal;
  filter: blur(0px);
}

.blob8 {
  background: radial-gradient(
      61.98% 61.98% at 100% 0%,
      rgba(32, 30, 147, 0.65) 0%,
      rgba(32, 30, 147, 0.2405) 49.77%,
      rgba(2, 209, 255, 0) 100%
    ),
    radial-gradient(
      50% 68.23% at 98.21% 96.61%,
      rgba(65, 209, 201, 0.73) 0%,
      rgba(65, 209, 183, 0.3358) 57.82%,
      rgba(65, 209, 183, 0) 100%
    ),
    radial-gradient(
      72.32% 89.58% at 1.34% 3.39%,
      #f74e76 0%,
      rgba(242, 87, 87, 0) 100%
    ),
    linear-gradient(180deg, #58ccdc 0%, rgba(88, 204, 220, 0) 100%),
    linear-gradient(62.9deg, #8532c7 14.56%, rgba(133, 50, 199, 0) 92.07%),
    #f2f5fa;
  background-blend-mode: normal, normal, normal, normal, normal, normal;
  filter: blur(0px);
}

.blob9 {
  background: radial-gradient(
      110.88% 79.69% at 47.77% 151.82%,
      #ffec45 0%,
      rgba(255, 236, 69, 0.3) 54.92%,
      rgba(255, 236, 69, 0) 96.11%
    ),
    radial-gradient(
      50% 68.23% at 98.21% 96.61%,
      #41d1b7 0%,
      rgba(65, 209, 183, 0.35) 49.27%,
      rgba(65, 209, 183, 0) 100%
    ),
    radial-gradient(
      83.71% 75.52% at -10.04% 2.86%,
      #8263db 0%,
      rgba(129, 114, 218, 0.26) 56.87%,
      rgba(129, 114, 218, 0) 100%
    ),
    linear-gradient(180deg, #58ccdc 0%, rgba(88, 204, 220, 0) 100%),
    linear-gradient(81.23deg, #ff557e 21.4%, rgba(255, 85, 85, 0) 84.87%),
    #f2f5fa;
  background-blend-mode: normal, normal, darken, normal, normal, normal;
  filter: blur(0px);
}

.blob10 {
  background: radial-gradient(
      ellipse at 50% 50%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(ellipse at 70% 60%, #c3e060 0%, rgba(195, 224, 96, 0) 90%),
    radial-gradient(ellipse at 30% 30%, #c3e060 0%, rgba(195, 224, 96, 0) 60%),
    radial-gradient(
      ellipse at bottom left,
      #00a3cb 0%,
      rgba(0, 163, 203, 0) 70%
    ),
    linear-gradient(
      135deg,
      rgba(18, 46, 119, 0) 0%,
      rgba(18, 46, 119, 0) 75%,
      #122e77 100%
    ),
    linear-gradient(
      to right,
      #625793 0%,
      #d55d64 35%,
      #e49129 65%,
      #c0671c 100%
    );
  background-blend-mode: overlay, luminosity, color-dodge, saturation, screen,
    color;
  filter: blur(0px);
}

.blob11 {
  background: radial-gradient(
      ellipse at 50% 50%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    radial-gradient(ellipse at 70% 60%, #c3e060 0%, rgba(195, 224, 96, 0) 90%),
    radial-gradient(ellipse at 30% 30%, #c3e060 0%, rgba(195, 224, 96, 0) 60%),
    radial-gradient(
      ellipse at bottom left,
      #00a3cb 0%,
      rgba(0, 163, 203, 0) 70%
    ),
    linear-gradient(
      135deg,
      rgba(18, 46, 119, 0) 0%,
      rgba(18, 46, 119, 0) 75%,
      #122e77 100%
    ),
    linear-gradient(
      to right,
      #625793 0%,
      #d55d64 35%,
      #e49129 65%,
      #c0671c 100%
    );
  background-blend-mode: lighten, saturation, screen, color, color-dodge,
    multiply;
  filter: blur(0px);
}

.blob12 {
  background: linear-gradient(
      197.37deg,
      #7450db -0.38%,
      rgba(138, 234, 240, 0) 101.89%
    ),
    linear-gradient(
      115.93deg,
      #3e88f6 4.86%,
      rgba(62, 180, 246, 0.33) 38.05%,
      rgba(62, 235, 246, 0) 74.14%
    ),
    radial-gradient(
      56.47% 76.87% at 6.92% 7.55%,
      rgba(62, 136, 246, 0.7) 0%,
      rgba(62, 158, 246, 0.182) 52.16%,
      rgba(62, 246, 246, 0) 100%
    ),
    linear-gradient(306.53deg, #2ee4e3 19.83%, rgba(46, 228, 227, 0) 97.33%);
  background-blend-mode: lighten, saturation, overlay, color-dodge, luminosity,
    multiply;
  filter: blur(0px);
}

.blob13 {
  background: radial-gradient(
      73.4% 100.98% at 95.54% 95.05%,
      rgba(255, 156, 65, 0.78) 0%,
      rgba(255, 168, 88, 0) 100%
    ),
    linear-gradient(197.37deg, #fff0a4 -0.38%, rgba(255, 240, 164, 0) 101.89%),
    radial-gradient(
      58.85% 73.83% at 9.82% 3.13%,
      rgba(49, 132, 255, 0.8) 0%,
      rgba(56, 75, 247, 0.208) 52.16%,
      rgba(62, 246, 246, 0) 100%
    ),
    linear-gradient(
      117.39deg,
      #3e9ef6 3.91%,
      rgba(238, 43, 183, 0.33) 37.48%,
      rgba(246, 62, 106, 0) 73.98%
    ),
    linear-gradient(306.53deg, #ffaadd 19.83%, rgba(255, 170, 221, 0) 97.33%);
  background-blend-mode: darken, multiply, screen, color-dodge, color, overlay;
  filter: blur(0px);
}

.blob14 {
  background: linear-gradient(180deg, #ef516d 0%, rgba(239, 81, 109, 0) 100%),
    radial-gradient(
      94.51% 124.88% at 94.32% 94.43%,
      rgba(65, 244, 255, 0.78) 0%,
      rgba(131, 218, 255, 0.6552) 32.29%,
      rgba(99, 175, 240, 0.3978) 64.06%,
      rgba(43, 90, 211, 0) 100%
    ),
    linear-gradient(313.04deg, #341d65 0.93%, #604aea 125.68%);
  background-blend-mode: luminosity, multiply, color-dodge, overlay, saturation,
    darken;
  filter: blur(0px);
}

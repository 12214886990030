@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::after,
*::before {
  @apply m-0 box-border p-0;
}

#root {
  @apply min-h-screen w-full overflow-x-hidden;
}

body {
  @apply min-h-screen w-full overflow-x-hidden;
}

main {
  min-height: calc(100vh - 4rem);
}

.h-tab-panel {
  height: calc(100vh - 3rem - 2.5rem);
  max-height: calc(100vh - 3rem - 2.5rem);
  overflow-y: hidden;
}

.h-board {
  height: calc(100vh - 3rem - 3rem - 2.25rem);
  max-height: calc(100vh - 3rem - 3rem - 2.25rem);
  overflow-y: hidden;
}

.h-compounding-board {
  height: calc(100vh - 3rem - 3rem);
  max-height: calc(100vh - 3rem - 3rem);
  overflow-y: hidden;
}

/* BUTTONS */

.btn-primary {
  @apply rounded border border-blue-600 bg-blue-600 px-3 py-2 text-base text-white hover:border-blue-700 hover:bg-blue-700 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-500;
}

.btn-primary-outline {
  @apply rounded border border-blue-600 px-3 py-2 text-base text-blue-600 hover:border-blue-700 hover:bg-blue-100 hover:text-blue-700 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-500;
}

.btn-danger {
  @apply rounded border border-transparent bg-red-600 px-3 py-2 text-base text-white hover:bg-red-700 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-500;
}

.btn-danger-outline {
  @apply rounded border border-red-600 px-3 py-2 text-base text-red-600 hover:border-red-700 hover:bg-red-100 hover:text-red-700 disabled:cursor-not-allowed disabled:border-gray-200 disabled:bg-gray-200 disabled:text-gray-500;
}

/* FORM */
[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  @apply w-full rounded border-gray-400 disabled:cursor-not-allowed disabled:bg-gray-100 disabled:text-gray-500;
}

textarea {
  @apply mb-0 block max-h-96 min-h-[10rem];
}

input::placeholder,
textarea::placeholder {
  @apply font-light text-gray-400;
}

label {
  @apply mb-1 inline-block text-sm text-gray-500;
}

.form-group {
  @apply relative mb-4 w-full;
}

.form-error,
.form-error:focus {
  @apply border-red-600 outline outline-2 outline-offset-2 outline-transparent ring-1 ring-red-600;
}

.form-error-text {
  @apply mt-1 block text-xs text-red-600;
}
.form-info-text {
  @apply mt-1 block text-xs text-gray-400;
}

/* TOAST */
.Toastify__toast-container {
  @apply w-96 text-slate-800;
}

.Toastify__toast {
  @apply min-h-0 border p-1;
}

.Toastify__toast-body {
  @apply m-0 flex items-start;
}

.Toastify__close-button {
  @apply self-center pb-0.5;
}

.Toastify__toast-icon {
  @apply mt-0.5;
}

.Toastify__toast-theme--light {
  @apply border-0 border-l-4;
}

.Toastify__toast-theme--light.Toastify__toast--success {
  @apply border-green-600;
}
.Toastify__toast-theme--light.Toastify__toast--error {
  @apply border-red-600;
}
.Toastify__toast-theme--light.Toastify__toast--info {
  @apply border-blue-600;
}
.Toastify__toast-theme--light.Toastify__toast--warning {
  @apply border-yellow-500;
}

.Toastify__toast--success .Toastify__toast-icon > svg {
  @apply fill-current text-green-600;
}
.Toastify__toast--error .Toastify__toast-icon > svg {
  @apply fill-current text-red-600;
}
.Toastify__toast--info .Toastify__toast-icon > svg {
  @apply fill-current text-blue-600;
}
.Toastify__toast--warning .Toastify__toast-icon > svg {
  @apply fill-current text-yellow-500;
}
